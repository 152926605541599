var jxapis = {
 "腾讯解析":"https://jx.xmflv.cc/?url=",
  "全民解析":"https://jx.aidouer.net/?url=",
  'go': "https://jx.playerjy.com?url=",
  "8090g": "https://www.8090g.cn/?url=",
  "8090g2": "https://www.8090g.cn/jiexi/?url=",
  "ckmov": "https://www.ckmov.vip/api.php?url=",
  "m3u8": "https://jx.m3u8.tv/jiexi/?url=",
  "心科技解析":"https://jx.jsonplayer.com/player/?url=",
}

export default jxapis;
